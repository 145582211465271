import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { fetchReservationsByDate, cancelReservation, updateReservation } from './api';
import moment from 'moment';
import { useAuth } from '../AuthContext';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const { logout } = useAuth();
  const [reservations, setReservations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editing, setEditing] = useState(null);
  const [editForm, setEditForm] = useState({ date: new Date(), time: '', guests: '', mealType: '', name: '', phone: '', email: '', note: '' });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    getReservationsForDate(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    getReservationsForDate(date);
  };

  const getReservationsForDate = async (date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const data = await fetchReservationsByDate(formattedDate); 
      const reservationsWithProgress = data.map(reservation => ({ ...reservation }));
      reservationsWithProgress.sort((a, b) => moment(a.time, 'HH:mm:ss').diff(moment(b.time, 'HH:mm:ss')));
      setReservations(reservationsWithProgress);
    } catch (error) {
      console.error('Failed to fetch reservations:', error);
      setReservations([]); // Ensure no outdated data is displayed in case of error
    }
  };

  const handleCancel = async (id) => {
    const confirmed = window.confirm("Are you sure you want to cancel this reservation?");
    if (!confirmed) return;

    try {
      await cancelReservation(id);
      setReservations(reservations.filter(reservation => reservation.id !== id));
    } catch (error) {
      alert('An error occurred while cancelling the reservation. Please try again.');
      console.error('Failed to cancel reservation:', error);
    }
  };

  const handleEdit = (reservation) => {
    setEditing(reservation.id);
    setEditForm({
      date: new Date(reservation.date),
      time: reservation.time,
      guests: reservation.guests,
      mealType: reservation.meal_type,
      name: reservation.name,
      phone: reservation.phone,
      email: reservation.email,
      note: reservation.note
    });
  };

  const handleSave = async () => {
    if (!editForm.time || !editForm.guests || !editForm.name || !editForm.phone || !editForm.email) {
      setErrors({ form: 'Please fill in all required fields.' });
      return;
    } else {
      setErrors({});
    }

    try {
      const updated = await updateReservation(editing, {
        ...editForm,
        date: moment(editForm.date).format('YYYY-MM-DD')
      });
      setEditing(null);
      await getReservationsForDate(selectedDate); // Refresh data after saving
    } catch (error) {
      console.error('Failed to update reservation:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };

  const handleTimeChange = (e) => {
    const time = e.target.value;
    let mealType = '';
    if (time === '12:00' || time === '12:30' || time === '13:00' || time === '13:30' || time === '14:00' || time === '14:30' || time === '15:00' || time === '15:30') { // Removed 11:30
      mealType = 'lunch';
    }
    setEditForm({ ...editForm, time, mealType });
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <div className="date-picker-container">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          className="date-picker"
        />
      </div>

      <div className="reservations-list">
        {reservations.length > 0 ? (
          reservations.map(reservation => (
            <div key={reservation.id} className="reservation-item">
              {editing === reservation.id ? (
                <div className="edit-form">
                  <DatePicker
                    selected={editForm.date}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    className="date-picker"
                  />
                  <select
                    name="time"
                    value={editForm.time}
                    onChange={handleTimeChange}
                    className="text-input"
                  >
                    <option value="">Select a time</option>
                    <option value="12:00">12:00</option>
                    <option value="12:30">12:30</option>
                    <option value="13:00">13:00</option>
                    <option value="13:30">13:30</option>
                    <option value="14:00">14:00</option>
                    <option value="14:30">14:30</option>
                    <option value="15:00">15:00</option>
                    <option value="15:30">15:30</option>
                  </select>
                  <input
                    type="number"
                    name="guests"
                    value={editForm.guests}
                    onChange={handleChange}
                    className="number-input"
                    placeholder="Guests"
                    min="1"
                  />
                  <input
                    type="text"
                    name="name"
                    value={editForm.name}
                    onChange={handleChange}
                    className="text-input"
                    placeholder="Name"
                  />
                  <input
                    type="text"
                    name="phone"
                    value={editForm.phone}
                    onChange={handleChange}
                    className="text-input"
                    placeholder="Phone"
                  />
                  <input
                    type="email"
                    name="email"
                    value={editForm.email}
                    onChange={handleChange}
                    className="text-input"
                    placeholder="Email"
                  />
                  <textarea
                    name="note"
                    value={editForm.note}
                    onChange={handleChange}
                    className="text-input"
                    placeholder="Note (Optional)"
                  />
                  {errors.form && <p className="error">{errors.form}</p>}
                  <button onClick={handleSave} className="save-button">Save</button>
                  <button onClick={() => setEditing(null)} className="cancel-button">Cancel</button>
                </div>
              ) : (
                <div className="reservation-details">
                  <p><strong>Date:</strong> {moment(reservation.date).format('YYYY-MM-DD')}</p>
                  <p><strong>Name:</strong> {reservation.name}</p>
                  <p><strong>Phone:</strong> {reservation.phone}</p>
                  <p><strong>Email:</strong> {reservation.email}</p>
                  <p><strong>Time:</strong> {reservation.time}</p>
                  <p><strong>People:</strong> {reservation.guests}</p>
                  <p><strong>Meal Type:</strong> {reservation.meal_type}</p>
                  <p><strong>Note:</strong> {reservation.note}</p>
                  <button onClick={() => handleEdit(reservation)} className="edit-button">Edit</button>
                  <div className="cancel-container">
                    <button 
                      className="cancel-button"
                      onClick={() => handleCancel(reservation.id)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No reservations for the selected date.</p>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;

































