// src/admin/AdminApp.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import AdminLogin from './AdminLogin';
import PrivateRoute from './PrivateRoute';
import NavBar from '../NavBar'; // Use the existing NavBar
import { useAuth } from '../AuthContext';

function AdminApp() {
  const { isAuthenticated } = useAuth();

  return (
    <>
      {isAuthenticated && <NavBar />} {/* Render navbar only if authenticated */}
      <Routes>
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/dashboard" element={<PrivateRoute element={<AdminDashboard />} />} />
      </Routes>
    </>
  );
}

export default AdminApp;
















