import React from 'react';

function TermsAndConditions({ nextStep, prevStep }) {
  const handleAgree = () => {
    nextStep();
  };

  const handleBack = () => {
    prevStep();
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-black text-white">
      <div className="flex flex-col items-start justify-center p-6 bg-black rounded shadow border border-white max-w-lg w-full">
        <h1 className="text-4xl font-bold mb-4">RFG terms & conditions - <span className="font-bold">LUNCH</span></h1>
        <div className="mb-4">
          <h2 className="text-xl font-bold">I. FIRST COME, FIRST SERVE basis;</h2>
          <ul className="list-disc list-inside pl-4">
            <li>Once we sell out of our set lunches, we will close for the remaining days (e.g., sell out on Friday, we will close on Saturday).</li>
            <li>Additional options: If we sell out of the set lunches, there will still be nibbles that you will be able to order!</li>
          </ul>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-bold">II. Allergies;</h2>
          <ul className="list-disc list-inside pl-4">
            <li>Dietary Restrictions: Please note that we are unable to accommodate allergies. We strive to provide a consistent dining experience for all our guests, and as such, we cannot guarantee allergen-free meals.</li>
          </ul>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-bold">III. Seating;</h2>
          <ul className="list-disc list-inside pl-4">
            <li>Lunch service will be in the outdoor courtyard.</li>
          </ul>
        </div>
        <p className="mt-4 text-sm">
          By making a reservation at RFG Restaurant, you acknowledge and accept these terms and conditions. Thank you for your understanding and cooperation.
        </p>
        <div className="flex justify-between w-full mt-6">
          <button
            className="py-3 px-6 rounded bg-black text-white border border-white"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            className="py-3 px-6 rounded bg-white text-black border border-white"
            onClick={handleAgree}
          >
            Agree
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;




