import React from 'react';

const WidgetReservation = () => {
  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe 
        data-id="nbi-widget" 
        src="https://bookings.nowbookit.com/?accountid=4fef2f61-ec2a-41a6-8eb2-b5b7a8115a50&venueid=12358&theme=dark&colors=hex,000000&banner=hidden" 
        style={{ width: '100%', height: '100%', border: 'none' }}
      ></iframe>
      <script src="https://plugins.nowbookit.com/iframe-resizer-build/bundle.js"></script>
    </div>
  );
};

export default WidgetReservation;
