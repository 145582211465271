import React, { useEffect } from 'react';

const MenuPage = () => {
  useEffect(() => {
    document.title = "RFG Menu"; // Set a default title for the Menu Page
  }, []);

  return (
    <div style={{
      padding: '60px 20px',
      maxWidth: '800px',
      margin: '0 auto',
      fontFamily: 'Arial, sans-serif',
      color: 'white',
      lineHeight: '1.8',
    }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'left' }}>RFG Menu</h1>

      <p style={{ height: '15px' }}></p>

      <p style={{ fontSize: '16px', lineHeight: '1.8', textAlign: 'left' }}>
        We are opening Lunch and Dinner, Monday-Saturday 12:00-21:30 from 28th August 2024.
      </p>

      <p style={{ height: '15px' }}></p>

      <p style={{ fontSize: '16px', lineHeight: '1.8', textAlign: 'left' }}>
        RFG Restaurant serves a <strong>Garden Lunch</strong> and <strong>RAW FLAME GRILL</strong> Menu. Our 
        <strong> “Garden Lunch”</strong> is designed for those seeking a quick, light, yet flavorful meal. For those 
        interested in a more indulgent experience, our <strong>“RAW FLAME GRILL”</strong> offers a preview of our 
        full set dinner concept. To complement your meal, we welcome you to bring your wine, with a $10 corkage fee per bottle.
      </p>

      <p style={{ height: '15px' }}></p>

      <p style={{ fontSize: '16px', lineHeight: '1.8', textAlign: 'left' }}>
        For reservations of 6+, please contact us.
      </p>

      <p style={{ height: '15px' }}></p>

      <ul style={{ listStyleType: 'none', paddingLeft: 0, fontSize: '16px', lineHeight: '1.8', textAlign: 'left' }}>
        <li style={{ marginBottom: '10px', fontWeight: 'bold' }}>
          <a
            href="/garden-lunch.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            Garden Lunch Menu
          </a>
        </li>

        <p style={{ height: '15px' }}></p>

        <li style={{ marginBottom: '10px', fontWeight: 'bold' }}>
          <a
            href="/raw-flame-grill.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            Raw Flame Grill Menu
          </a>
        </li>

        <p style={{ height: '15px' }}></p>

        <li style={{ marginBottom: '10px', fontWeight: 'bold' }}>
          <a
            href="/september-menu.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            September Dinner Set Menu - First booking available from 09/09/24
          </a>
        </li>

        <p style={{ height: '15px' }}></p>

        <li style={{ marginBottom: '10px', fontWeight: 'bold' }}>
          <a
            href="/drinks.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            Drinks
          </a>
        </li>
      </ul>

      <p style={{ height: '15px' }}></p>

      <p style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '1.8', textAlign: 'left' }}>Contact</p>
      <p style={{ fontSize: '16px', lineHeight: '1.8', textAlign: 'left' }}>
        Now Open Monday to Saturday 12:00 - 21:30
        <br />
        1/333 Parnell Road, Parnell, Auckland
      </p>
      <p style={{ fontSize: '16px', lineHeight: '1.8', textAlign: 'left' }}>
        Email: <a href="mailto: DINING@RFG.NZ" style={{ color: 'white', textDecoration: 'underline' }}>DINING@RFG.NZ</a>
        <br />
        Phone: <a href="tel:+64211082668" style={{ color: 'white', textDecoration: 'underline' }}>+64211082668</a>
      </p>
    </div>
  );
};

export default MenuPage;




















