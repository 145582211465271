import React from 'react';
import './App.css';  // Ensure this path is correct
import heroImage from './image/home_bg.jpg';  // Correct path to the new image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const HeroSection = () => {
    return (
        <div>
            <div className="hero-section" style={{ backgroundImage: `url(${heroImage})` }}>
                <h1 className="hero-title">RFG Restaurant</h1>
            </div>
            <div className="contact-info">
                <p>1/333 Parnell Road, Parnell, Auckland 1052</p>
                <p>021 108 2668</p>
                <p>DINING@RFG.NZ</p>
                <a href="https://www.instagram.com/rfgrestaurant/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="instagram-icon" />{' '}
                        rfgrestaurant
                </a>
            </div>
        </div>
    );
};

export default HeroSection;





