import React, { useState, useEffect } from 'react';
import { useReservation } from './ReservationContext';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import './custom-datepicker.css';
import moment from 'moment';

function TimeDateSelectionForm({ nextStep, prevStep }) {
  const { reservation, updateReservation } = useReservation();
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(reservation.time);
  const [remainingSeats, setRemainingSeats] = useState(null);
  const [errors, setErrors] = useState({});
  const [fullyBooked, setFullyBooked] = useState(false);
  const [showAvailability, setShowAvailability] = useState(null);

  const lunchTypes = [
    "Chef Table Sashimi & Nigiri",
    "Whole RFG Tomahawk",
    "RFG Full Rack Lamb Rack",
    "RFG GAME MEAT"
  ];

  useEffect(() => {
    const times = reservation.mealType === 'lunch'
      ? ['12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30'] 
      : ['18:30', '19:30'];

    setAvailableTimes(times);

    const fetchSeatAvailability = async (selectedDate) => {
      try {
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
        const response = await axios.post(
          `https://rfg.nz/server3.php/api/reservations/seats`,
          { queryDate: formattedDate }
        );

        const remaining = response.data.remainingSeats;

        setRemainingSeats(remaining);
        setFullyBooked(remaining <= 0);
      } catch (error) {
        console.error('Error fetching seat availability:', error);
      }
    };

    if (reservation.date) {
      fetchSeatAvailability(reservation.date);
    }
  }, [reservation.date, reservation.mealType]);

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
    updateReservation({ ...reservation, time: time });
    setErrors((prevErrors) => ({ ...prevErrors, time: '' }));
    setShowAvailability(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = moment.utc(date).format('YYYY-MM-DD');
    updateReservation({ ...reservation, date: formattedDate, guests: 1 });
    setErrors((prevErrors) => ({ ...prevErrors, date: '' }));
  };

  const handleGuestChange = (amount) => {
    const maxGuests = remainingSeats !== null ? Math.min(remainingSeats, 10) : 10;
    if (amount >= 1 && amount <= maxGuests) {
      updateReservation({ ...reservation, guests: amount });
      setErrors((prevErrors) => ({ ...prevErrors, guests: '' }));
    }
  };

  const handleNoteChange = (event) => {
    updateReservation({ ...reservation, note: event.target.value });
  };

  const handleLunchTypeSelection = (lunchType) => {
    updateReservation({ ...reservation, lunch_type: lunchType });
  };

  const handleSubmit = () => {
    const newErrors = {};

    if (!reservation.date) newErrors.date = 'Date is required';
    if (!reservation.time) newErrors.time = 'Time is required';
    if (!reservation.guests) newErrors.guests = 'Number of guests is required';
    if (!reservation.lunch_type) newErrors.lunch_type = 'Lunch Type is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    nextStep();
  };

  const getDayClassName = (date) => {
    return date.getDay() === 0 ? 'react-datepicker__day--sunday tooltip' : '';
  };

  const isDayDisabled = (date) => {
    return date.getDay() === 0;
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-black text-white">
      <div className="flex flex-col items-center justify-center p-6 bg-black rounded shadow border border-white max-w-lg w-full">
        <DatePicker
          selected={reservation.date}
          onChange={handleDateChange}
          minDate={new Date()}
          dayClassName={getDayClassName}
          filterDate={(date) => !isDayDisabled(date)}
          inline
          className="mb-4 text-black react-datepicker"
        />
        {errors.date && <div className="text-red-500 text-sm mb-2">{errors.date}</div>}
        
        <div className="lunch-type-container grid grid-cols-2 gap-4 mb-4">
          {lunchTypes.map((type) => (
            <button
              key={type}
              className={`lunch-type-button ${reservation.lunch_type === type ? 'selected' : ''} bg-gray-800 text-white py-2 px-4 rounded`}
              onClick={() => handleLunchTypeSelection(type)}
            >
              {type}
            </button>
          ))}
        </div>
        {errors.lunch_type && <div className="text-red-500 text-sm mb-2">{errors.lunch_type}</div>}
        
        <div className="time-slot-container my-4">
          {availableTimes.map((time) => (
            <button
              key={time}
              className={`time-slot-button ${selectedTime === time ? 'selected' : ''} ${fullyBooked ? 'disabled' : ''}`}
              onClick={() => handleTimeSelection(time)}
              disabled={fullyBooked}
            >
              {time}
            </button>
          ))}
        </div>
        {fullyBooked && (
          <div className="text-red-500 text-sm mb-2">
            All time slots are fully booked for this date. Please select another date.
          </div>
        )}
        {!fullyBooked && remainingSeats !== null && (
          <div className="text-white text-sm mb-2">
            {remainingSeats} seats remaining for this date.
          </div>
        )}
        {errors.time && <div className="text-red-500 text-sm mb-2">{errors.time}</div>}
        <div className="flex items-center justify-center my-4">
          <button
            className="bg-black text-white border border-white rounded px-5 py-3 mx-2 hover:bg-gray-800"
            onClick={() => handleGuestChange(reservation.guests - 1)}
            disabled={reservation.guests <= 1}
          >
            -
          </button>
          <span className="text-2xl mx-4">
            {reservation.guests} {reservation.guests === 1 ? 'Guest' : 'Guests'}
          </span>
          <button
            className="bg-black text-white border border-white rounded px-5 py-3 mx-2 hover:bg-gray-800"
            onClick={() => handleGuestChange(reservation.guests + 1)}
            disabled={reservation.guests >= (remainingSeats !== null ? Math.min(remainingSeats, 10) : 10)}
          >
            +
          </button>
        </div>
        {errors.guests && <div className="text-red-500 text-sm mb-2">{errors.guests}</div>}
        <textarea
          className="w-full p-2 mt-4 text-black"
          placeholder="Add a note (e.g., Birthday celebration, Food allergies)"
          value={reservation.note}
          onChange={handleNoteChange}
        ></textarea>
        <div className="flex items-center justify-center mt-4 space-x-4">
          <button
            className="bg-black text-white border border-white hover:bg-gray-800 font-bold py-3 px-6 rounded"
            onClick={prevStep}
          >
            Back
          </button>
          <button
            className="bg-black text-white border border-white hover:bg-gray-800 font-bold py-3 px-6 rounded"
            onClick={handleSubmit}
            disabled={fullyBooked}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default TimeDateSelectionForm;





































































