// src/admin/api.js
export const fetchReservations = async () => {
  try {
    const response = await fetch('https://rfg.nz/server3.php/api/reservations/query',  {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      console.error('Error:', errorResponse);
      throw new Error('Failed to fetch reservations');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during fetchReservations:', error);
    throw error;
  }
};

export const updateReservation = async (id, updates) => {
  try {
    const response = await fetch(`https://rfg.nz/server3.php/api/reservations/update?id=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      console.error('Error:', errorResponse);
      throw new Error('Failed to update reservation');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during updateReservation:', error);
    throw error;
  }
};

export const cancelReservation = async (id) => {
  try {
    const response = await fetch(`https://rfg.nz/server3.php/api/reservations/delete?id=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      console.error('Error:', errorResponse);
      throw new Error('Failed to cancel reservation');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during cancellation:', error);
    throw error;
  }
};

export const fetchReservationsByDate = async (date) => {
  try {
    const response = await fetch(`https://rfg.nz/server3.php/api/reservations/query?date=${date}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      console.error('Error:', errorResponse);
      throw new Error('Failed to fetch reservations for the selected date');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during fetchReservationsByDate:', error);
    throw error;
  }
};


  
  
  